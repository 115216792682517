
import { inject } from '@/inversify';
import { Component, Prop, Vue } from 'vue-property-decorator';
import type Day from '@/modules/common/types/day.type';

import RatesService, { RatesServiceS } from '@/modules/rates/rates.service';
import RatesAnalysisService, { RatesAnalysisServiceS } from '@/modules/rates/rates-analysis.service';
import BaseCard from './hover-base-card.vue';

@Component({ components: { BaseCard } })
export default class SoldOut extends Vue {
    @inject(RatesServiceS)
    private ratesService!: RatesService;

    @inject(RatesAnalysisServiceS)
    private ratesAnalysisService!: RatesAnalysisService;

    @Prop({
        required: true,
        type: Number,
    })
    private day!: Day;

    @Prop({
        type: Boolean,
    })
    private isComparison!: boolean;

    private get hotelId() {
        return +this.$route.params.hotelId;
    }

    private get pax() {
        const service = this.isComparison
            ? this.ratesAnalysisService
            : this.ratesService;

        return service.getCheckinDay(this.day)?.hotels[this.hotelId]?.pax;
    }

    get outputText() {
        return this.pax
            ? this.$tc('nog', 0, [this.pax])
            : this.$tc('soldOut');
    }

    get outputTitle() {
        return this.pax
            ? this.$tc('rates.nogAvailable', 0, [this.pax])
            : undefined;
    }
}
